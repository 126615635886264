<template>
  <div></div>
</template>

<script setup>
import { computed, onMounted, watch } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useCookie } from '@vue-composable/cookie'

import { USER_TYPES } from '../definitions'
import { waitForElm } from '../utils/utilities'

const store = useStore()
const router = useRouter()
const initialized = computed(() => store.state.initialized)
const costCenterId = computed(() => store.state.selectedCostCenterId)
const user = computed(() => store.state.user)

const { cookie, removeCookie } = useCookie('__redirect__')

// eslint-disable-next-line no-unused-vars
const hideIntercomLauncher = () => {
  // Hide intercom launcher.
  waitForElm('.intercom-lightweight-app').then((el) => {
    el.style.display = 'none'
  })
}

const handleRedirect = () => {
  if (cookie.value) {
    const { name, params, query } = JSON.parse(cookie.value)
    removeCookie()
    router.replace({ name, params, query })
    return
  }

  if (user.value?.type === USER_TYPES.INTERNAL) {
    router.replace({ name: 'forms' })
  } else if (user.value?.type === USER_TYPES.EXTERNAL) {
    router.replace({ name: 'utilization', params: { costCenterId: costCenterId.value } })
  } else {
    console.log('Unknown user type')
    // Redirect to error page
    router.push({ name: 'access-denied' })
  }
}

const onInitialized = () => {
  handleRedirect()
  // hideIntercomLauncher()
}

onMounted(() => {
  if (initialized.value) {
    onInitialized()
  } else {
    const stop = watch(initialized, (value) => {
      if (!value) return
      onInitialized()
      stop()
    })
  }
})
</script>
